import axios from "axios";

const BASIC_URL=process.env.REACT_APP_N7_OFFCHAIN_API_URL;

const getCoList = () => {
    const url=BASIC_URL + "/cos";
    return axios.get(url);
}

const getCoId = (coName) => {
    let co = {
        coName: coName
    }
    const url=BASIC_URL;
    return axios.post(url, co);
}

const getCoByHash = (coHashId) => {
    const url = BASIC_URL + "/" + coHashId;
    return axios.get(url);
}

const getUserInfo = (coUserName) => {
    const encodedCoUserName = encodeURIComponent(coUserName);
    const url=BASIC_URL + "/coUser/" + encodedCoUserName;
    return axios.get(url);
}

const addNewCo = (coUser) => {
    const url = BASIC_URL + "/coUser";
    return axios.post(url, coUser);
}

export { getCoList, getCoId, getCoByHash, getUserInfo, addNewCo };
