import axios from "axios";

const BASIC_URL=process.env.REACT_APP_N7_STREAM_API_URL;

const getAllAssets = () => {
    const url=BASIC_URL + "/content/all";
    return axios.get(url);
}

const uploadContent = (selectedContent) => {
    const url=BASIC_URL + "/content";
    return axios.post(url, selectedContent);
}

const deleteContent = (selectedContent) => {
    const url=BASIC_URL + "/content";
    return axios.delete(url, {data: selectedContent});
}

const createNewStream = (coStream) => {
    const url=BASIC_URL + "/stream";
    return axios.post(url, coStream);
}

const getStream = (coStreamId) => {
    const url=BASIC_URL + "/stream/" + coStreamId;
    return axios.get(url);
}

const deleteStream = (coStreamId) => {
    const url=BASIC_URL + "/stream/" + coStreamId;
    return axios.delete(url);
}

const getAllStreams = () => {
    const url=BASIC_URL + "/streams";
    return axios.get(url);
}

export {getAllAssets, uploadContent, deleteContent, createNewStream, getStream, deleteStream, getAllStreams};
